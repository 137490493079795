import styled from 'styled-components'

const black = '#000000'
const green = '#a9f65f'
const red = '#ff0033'

const Wrapper = styled.div`
  transition: top 0.5s ease-in-out;
  position: fixed;
  top: ${(props) => (props.displayNotification ? '1.5em' : '-10em')};
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5em;
  padding: 1em 5em;
  text-align: center;
  border-radius: 5px;
  background: ${black};
  border: 3px solid ${(props) => (props.isSuccessful ? green : red)};
`

const Text = styled.span`
  display: block;
  font-size: 20px;

  &:first-child {
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const Link = styled.a`
  display: inline;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
  text-decoration: underline;
`

const Notification = ({ message, isSuccessful, displayNotification }) => (
  <Wrapper displayNotification={displayNotification}>
    <Popup isSuccessful={isSuccessful}>
      <Text>
        {message}
        {isSuccessful}
      </Text>
      {isSuccessful && (
        <Text>
          View tokens on{' '}
          <Link
            href={
              message.includes('City Maps')
                ? 'https://opensea.io/collection/metaworldmaps-citymaps'
                : 'https://opensea.io/collection/metaworldmaps-project-meta-expedition'
            }
            target='_blank'
          >
            OpenSea
          </Link>
          .
        </Text>
      )}
    </Popup>
  </Wrapper>
)

export default Notification
