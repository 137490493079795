import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Collapse } from 'react-collapse'
import ReactCardFlip from 'react-card-flip'
import { loadModules } from 'esri-loader'
import { useLocation } from 'react-router-dom'

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Loader from './components/Loader'
import Notification from './components/Notification'

import ProjectMetaExpeditionTitle from './assets/project-meta-expedition-title.svg'
import CityMapsTitle from './assets/city-maps-title.svg'
import CityMapsIllustration from './assets/city-maps.png'
import PlusGfx from './assets/plus.svg'
import MinusGfx from './assets/minus.svg'
import DiscardGfx from './assets/discard.svg'
import CheckmarkGfx from './assets/checkmark.svg'
import MintedGfx from './assets/minted.svg'
import ArrowGfx from './assets/arrow.svg'

import pmeAbi from './abis/pme.json'
import cityMapsAbi from './abis/cityMaps.json'
import oldContractAbi from './abis/oldContract.json'
import pmeTokens from './pmeTokens.json'

const white = '#ffffff'
const pink = '#FF00AA'
const green = '#a9f65f'
const grey = '#adadad'
const darkGrey = '#3a3a3a'
const black = '#000000'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Button = styled.button`
  color: ${pink};
  background: ${black};
  border: 5px solid ${pink};
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  padding: 0 1em;
  cursor: pointer;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  margin-left: ${(props) => (props.moreMarginLeft ? '0.5em' : '0')};
  margin-bottom: ${(props) =>
    props.moreMargin ? '3em' : props.lessMargin ? '1.5em' : 0};

  &:hover {
    background: ${pink};
    color: ${white};
  }

  @media screen and (max-width: 992px) {
    height: 40px;
    font-size: 16px;
    border: 3px solid ${pink};
  }
`

const Section = styled.div`
  text-align: center;
  padding: 5em 5em;

  @media screen and (max-width: 992px) {
    padding: 5em 1em;
  }
`

const ImageTitle = styled.img`
  height: 65px;
  width: 100%;
  margin-bottom: 1.5em;
`

const Title = styled.h1`
  font-size: 35px;
  font-weight: bold;
  color: ${(props) => (props.green ? green : white)};
  margin: 0;
  margin-bottom: ${(props) => (props.moreMargin ? '1.5em' : '0.5em')};

  @media screen and (max-width: 992px) {
    font-size: 24px;
  }
`

const Text = styled.p`
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  margin-bottom: ${(props) => (props.moreMargin ? '1.5em' : '0.5em')};
  margin-top: ${(props) => (props.moreMarginTop ? '1.5em' : '0')};

  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
`

const SmallText = styled.span`
  display: block;
  font-size: 20px;
  font-style: italic;
  margin-bottom: ${(props) => (props.moreMargin ? '3em' : '1.5em')};

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const CityMaps = styled.img`
  display: block;
  margin: 5em auto;
  width: 100%;
  max-width: 1280px;
`

const AmountPicker = styled.div`
  border: 2px solid ${pink};
  max-width: 450px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin: 2em auto;

  @media screen and (max-width: 992px) {
    max-width: 226px;
    height: 40px;
  }
`

const SignWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${pink};
  width: 130px;
  height: 100%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 992px) {
    max-width: 60px;
  }
`
const Plus = styled.img`
  width: 45px;

  @media screen and (max-width: 992px) {
    width: 20px;
  }
`

const Minus = styled.img`
  width: 45px;

  @media screen and (max-width: 992px) {
    width: 20px;
  }
`

const Counter = styled.span`
  font-size: 50px;
  font-weight: bold;

  @media screen and (max-width: 992px) {
    font-size: 24px;
  }
`

const Tokens = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

const GalleryView = styled.div`
  width: calc(100% - 15px);
  height: 100%;
  max-height: 850px;
  border: 1.5px solid transparent;
  display: grid;
  overflow-y: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding-right: 15px;
  scrollbar-width: auto;
  scrollbar-color: ${green} transparent;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border: 2px solid ${green};
    padding: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${green};
    border-radius: 0px;
    border: 0px solid ${green};
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const GalleryViewEmpty = styled.div`
  width: calc(100% - 15px);
  height: 100%;
  max-height: 850px;
  border: 1.5px solid transparent;
  padding-right: 15px;
  font-size: 25px;
  font-weight: bold;

  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
`

const TokenWrapper = styled.div`
  box-sizing: border-box;
  transition: border 0.2s ease-in-out;
  border: ${(props) => {
    if (props.minted) return `5px solid ${green}`
    else if (props.selected) return `5px solid ${pink}`
    else return '5px solid transparent'
  }};
  position: relative;
  cursor: pointer;
  height: 100%;
`

const Token = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  filter: ${(props) =>
    props.selected || props.minted ? `brightness(30%)` : 'none'};
`

const SelectedToken = styled.div`
  width: 280px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${darkGrey};
  padding: 25px 0;

  @media screen and (max-width: 992px) {
    width: 200px;
    padding: 15px 0;
  }
`

const SelectedTokenText = styled.p`
  font-size: 25px;
  margin: 0;

  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
`

const PurpleText = styled.span`
  color: ${pink};
  font-weight: bold;
`

const Discard = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    width: 13px;
    height: 13px;
  }
`

const Checkmark = styled.img`
  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.selected ? '1' : '0')};
  width: 70px;
  height: 50px;
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 25px);
  cursor: pointer;
`

const Minted = styled.div`
  width: 100px;
  height: 150px;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 75px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MintedMark = styled.img`
  width: 100px;
  height: 100px;
`

const MintedText = styled.span`
  color: ${green};
  font-weight: bold;
  font-size: 16px;
  margin-top: 0.5em;
`

const Filters = styled.div`
  width: calc(100% - 2em);
  max-width: 300px;
  height: calc(850px - 3em);
  border: 1.5px solid ${darkGrey};
  margin-right: 30px;
  text-align: left;
  padding: 1.5em 1em;
  border-radius: 5px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border: 2px solid ${green};
    padding: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${green};
    border-radius: 0px;
    border: 0px solid ${green};
  }

  @media screen and (max-width: 992px) {
    height: auto;
    margin-right: 0;
    margin-bottom: 5em;
  }
`

const FilterTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.green ? green : white)};
  margin-bottom: ${(props) => (props.moreMargin ? '1.5em' : '1em')};

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const FilterSearch = styled.input`
  width: calc(100% - 2em);
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 5px;
  background: ${darkGrey};
  height: 50px;
  color: ${white};
  font-size: 18px;
  outline: none;
  padding: 0 1em;
  margin-bottom: 1.5em;
  transition: border 0.2s ease-in-out;
  caret-color: ${green};

  &:focus {
    border: 1px solid ${green};
  }

  @media screen and (max-width: 992px) {
    height: 40px;
    font-size: 16px;
  }
`

const PropertyTitle = styled.span`
  transition: color 0.2s ease-in-out;
  display: block;
  font-size: 18px;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: ${grey};
  cursor: pointer;
  color: ${(props) => (props.isOpened ? green : grey)};

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const Arrow = styled.img`
  transition: all 0.2s ease-in-out;
  height: 15px;
  margin-right: 10px;
  filter: ${(props) =>
    props.isOpened
      ? 'brightness(0) saturate(100%) invert(86%) sepia(25%) saturate(977%) hue-rotate(36deg) brightness(103%) contrast(93%);'
      : 'none'};
  transform: ${(props) => (props.isOpened ? 'rotate(90deg)' : 'none')};

  @media screen and (max-width: 992px) {
    height: 8px;
    margin-right: 5px;
  }
`

const Checkbox = styled.input`
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid ${green};
  border-radius: 1px;
  outline: none;
  background-color: ${black};
  cursor: pointer;
  width: 15px;
  height: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  top: -1px;

  &:checked {
    border: 1px solid ${pink};
    background-color: ${pink};
  }

  @media screen and (max-width: 992px) {
    top: -2px;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`

const CheckboxLabel = styled.label`
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const CheckboxWrapper = styled.div`
  margin-bottom: 0.5em;
  padding: 0 1.5em;
  display: flex;
  align-items: center;
`

const FilterSearchWrapper = styled.div`
  position: relative;
`

const FilterSearchDiscard = styled.img`
  transition: opacity 0.2s ease-in-out;
  z-index: ${(props) => (props.visible ? '1' : '-99')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  filter: brightness(0) saturate(100%) invert(86%) sepia(25%) saturate(977%)
    hue-rotate(36deg) brightness(103%) contrast(93%);
  position: absolute;
  right: 10px;
  top: 17px;
  width: 16px;
  height: 16px;
  cursor: ${(props) => (props.visible ? 'pointer' : 'normal')};

  @media screen and (max-width: 992px) {
    top: 12px;
  }
`

const FilterText = styled.span`
  transition: opacity 0.2s ease-in-out;
  z-index: ${(props) => (props.visible ? '1' : '-99')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  display: block;
  font-size: 18px;
  cursor: pointer;
  margin-top: 0.5em;
  text-decoration: underline;
  color: ${green};

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`
const Backside = styled.div`
  width: 100px;
  height: 150px;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 75px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const BacksideText = styled.text`
  color: ${green};
  font-weight: bold;
  font-size: 16px;
  margin-bottom: ${(props) => (props.moreMargin ? '1.5em' : '0')};
`

const BacksideWhiteText = styled.span`
  color: ${white};
  font-weight: bold;
  font-size: 16px;
`

const LinkButton = styled.a`
  color: ${pink};
  background: transparent;
  border: 3px solid ${pink};
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  min-height: 50px;
  padding: 0.3em 1em;
  cursor: pointer;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background: ${pink};
    color: ${white};
  }
`

const allStates = [
  'N/A',
  'Kansas',
  'Nevada',
  'Louisiana',
  'New Mexico',
  'Florida',
  'West Virginia',
  'Texas',
  'Utah',
  'Missouri',
  'Georgia',
  'Mississippi',
  'Arkansas',
  'Arizona',
  'Tennessee',
  'Kentucky',
  'Hawaii',
  'Colorado',
  'California',
  'Oklahoma',
  'Virginia',
  'Illinois',
  'South Carolina',
  'Ohio',
  'North Carolina',
  'Alabama',
  'Puerto Rico',
  'Indiana',
  'Delaware',
  'Maryland',
  'New Jersey',
  'Minnesota',
  'Oregon',
  'Idaho',
  'Washington',
  'South Dakota',
  'Montana',
  'Connecticut',
  'Pennsylvania',
  'North Dakota',
  'Maine',
  'Iowa',
  'Wyoming',
  'Michigan',
  'Alaska',
  'New York',
  'Wisconsin',
  'Massachusetts',
  'Nebraska',
  'New Hampshire',
  'Vermont',
  'Rhode Island',
]

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [userAddress, setUserAddress] = useState()
  const [cityMapsContract, setCityMapsContract] = useState()
  const [cityMapsToMint, setCityMapsToMint] = useState(1)
  const [cityMapsMaxMint, setCityMapsMaxMint] = useState()
  const [cityMapsMaxSupply, setCityMapsMaxSupply] = useState()
  const [cityMapsMinted, setCityMapsMinted] = useState()
  const [cityMapsCost, setCityMapsCost] = useState()
  const [displayNotification, setDisplayNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState()
  const [notificationSuccess, setNotificationSuccess] = useState()
  const [pmeContract, setPmeContract] = useState()
  const [pmeToMint, setPmeToMint] = useState([])
  const [pmeMaxSupply, setPmeMaxSupply] = useState()
  const [pmeCost, setPmeCost] = useState()
  const [pmeMaxMint, setPmeMaxMint] = useState()
  const [pmeMinted, setPmeMinted] = useState()
  const [pmeMintedTokens, setPmeMintedTokens] = useState([])
  const [oldContract, setOldContract] = useState()
  const [filterSearch, setFilterSearch] = useState('')
  const [mapsForDisplay, setMapsForDisplay] = useState([])
  const [filters, setFilters] = useState([])
  const [coastalClubOpened, setCoastalClubOpened] = useState(false)
  const [continentOpened, setContinentOpened] = useState(false)
  const [countryOpened, setCountryOpened] = useState(false)
  const [stateOpened, setStateOpened] = useState(false)
  const [mountainHighClubOpened, setMountainHighClubOpened] = useState(false)
  const [fishingClubOpened, setFishingClubOpened] = useState(false)
  const [sportsCommissionerClubOpened, setSportsCommissionerClubOpened] =
    useState(false)
  const [adventureSeekerClubOpened, setAdventureSeekerClubOpened] =
    useState(false)
  const [desertRatClubOpened, setDesertRatClubOpened] = useState(false)
  const [flipped, setFlipped] = useState()
  const pmeRef = useRef(null)
  const galleryRef = useRef(null)
  const cmRef = useRef(null)
  const location = useLocation()
  const [mapsToShow, setMapsToShow] = useState(20)

  const pmeMint = async () => {
    try {
      const tx = await pmeContract.mintRegular(pmeToMint, {
        value: ethers.utils.parseEther(
          ((pmeCost * pmeToMint.length) / 1000000000000000000).toString()
        ),
      })

      setIsLoaded(false)

      await tx.wait()

      setPmeMintedTokens((prev) => [...prev, ...pmeToMint])
      setPmeMinted((prev) => prev + pmeToMint.length)
      setPmeToMint([])
      setIsLoaded(true)
      showNotification(
        `You have successfully minted ${
          pmeToMint.length
        } Project Meta-Expedition ${
          pmeToMint.length > 1 ? 'tokens' : 'token'
        }.`,
        true
      )
    } catch (error) {
      showNotification(error, false)
    }
  }

  const pmeWlMint = async () => {
    try {
      const tx = await pmeContract.mintWL(pmeToMint)

      setIsLoaded(false)

      await tx.wait()

      setPmeMintedTokens((prev) => [...prev, ...pmeToMint])
      setPmeMinted((prev) => prev + pmeToMint.length)
      setPmeToMint([])
      setIsLoaded(true)
      showNotification(
        `You have successfully minted ${
          pmeToMint.length
        } Project Meta-Expedition ${
          pmeToMint.length > 1 ? 'tokens' : 'token'
        }.`,
        true
      )
    } catch (error) {
      showNotification(error, false)
    }
  }

  const pmeExchange = async () => {
    try {
      await oldContract.setApprovalForAll(
        '0x16c2cF181922f6EbC83E916a6DcBD58B2F992d88',
        true
      )

      const tx = await pmeContract.mintBurnExchange(userAddress)

      setIsLoaded(false)

      await tx.wait()

      setIsLoaded(true)
      showNotification(`You have successfully exchanged tokens.`, true)
    } catch (error) {
      showNotification(error, false)
    }
  }

  const cityMapsMint = async () => {
    try {
      const tx = await cityMapsContract.mint(cityMapsToMint, {
        value: ethers.utils.parseEther(
          ((cityMapsCost * cityMapsToMint) / 1000000000000000000).toString()
        ),
      })

      setIsLoaded(false)

      await tx.wait()

      setCityMapsMinted((prev) => prev + cityMapsToMint)
      setIsLoaded(true)
      showNotification(
        `You have successfully minted ${cityMapsToMint} City Maps ${
          cityMapsToMint > 1 ? 'tokens' : 'token'
        }.`,
        true
      )
    } catch (error) {
      showNotification(error, false)
    }
  }

  const showNotification = (_message, isSuccessful) => {
    let message = _message

    if (!isSuccessful) {
      if (_message.data) {
        message = _message.data.message.replace('execution reverted: ', '')
      } else {
        message = 'Something went wrong...'
      }
    }

    setNotificationMessage(message)
    setNotificationSuccess(isSuccessful)
    setDisplayNotification(true)

    setTimeout(() => {
      setDisplayNotification(false)
    }, 10000)
  }

  const getPmeMintedTokens = async () => {
    const { data } = await axios.get(
      'https://deep-index.moralis.io/api/v2/nft/0x16c2cF181922f6EbC83E916a6DcBD58B2F992d88/owners?chain=polygon',
      {
        headers: {
          'X-API-Key':
            '268iDzlNnh5vlRi2xuv9NXLXAMAZYbRFB5pmBeRwczCfAaspyU9qbXDjnCu58uYj',
        },
      }
    )

    const _pmeMintedTokens = []

    data.result.forEach((result) => _pmeMintedTokens.push(result.token_id))

    setPmeMintedTokens(_pmeMintedTokens)
  }

  const initConnection = async () => {
    try {
      setIsLoaded(false)

      if (typeof window.ethereum === 'undefined')
        return alert('Please install MetaMask...')
      if (!window.ethereum.isMetaMask)
        return alert('Please install MetaMask...')

      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      setUserAddress(accounts[0])

      const provider = new ethers.providers.Web3Provider(window.ethereum)

      const signer = provider.getSigner()

      const _oldContract = new ethers.Contract(
        '0x2953399124F0cBB46d2CbACD8A89cF0599974963',
        oldContractAbi,
        signer
      )
      setOldContract(_oldContract)

      const _pmeContract = new ethers.Contract(
        '0x16c2cF181922f6EbC83E916a6DcBD58B2F992d88',
        pmeAbi,
        signer
      )
      setPmeContract(_pmeContract)
      const pmeTotalSupply = await _pmeContract.totalSupply()
      setPmeMinted(pmeTotalSupply.toNumber())
      const pmeMaxSupply = await _pmeContract.maxSupply()
      setPmeMaxSupply(pmeMaxSupply.toNumber())
      const pmeMaxMint = await _pmeContract.maxMintAmountPerTx()
      setPmeMaxMint(pmeMaxMint.toNumber())
      const pmeCost = await _pmeContract.cost()
      setPmeCost(pmeCost)

      const _cityMapsContract = new ethers.Contract(
        '0x4febb92a58abc202f8cdea1dec755934773b1a15',
        cityMapsAbi,
        signer
      )
      setCityMapsContract(_cityMapsContract)
      const cityMapsTotalSupply = await _cityMapsContract.totalSupply()
      setCityMapsMinted(cityMapsTotalSupply.toNumber())
      const cityMapsMaxSupply = await _cityMapsContract.maxSupply()
      setCityMapsMaxSupply(cityMapsMaxSupply.toNumber())
      const cityMapsMaxMintAmount = await _cityMapsContract.maxMintAmount()
      setCityMapsMaxMint(cityMapsMaxMintAmount.toNumber())
      const cityMapsCost = await _cityMapsContract.cost()
      setCityMapsCost(cityMapsCost)

      window.ethereum.on('accountsChanged', () => {
        setCityMapsContract()
        setPmeContract()
        setOldContract()
      })

      setIsLoaded(true)
    } catch (error) {
      console.log(error)
    }
  }

  const checkConnection = async () => {
    try {
      if (typeof window.ethereum === 'undefined')
        return alert('Please install MetaMask...')
      if (!window.ethereum.isMetaMask)
        return alert('Please install MetaMask...')

      const provider = new ethers.providers.Web3Provider(window.ethereum)

      const accounts = await provider.listAccounts()

      if (accounts.length > 0) {
        initConnection()
      } else {
        setIsLoaded(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleTokenSelect = (rawName) => {
    const id = rawName.split('-')[1]

    if (
      !pmeMintedTokens.includes(id) &&
      pmeContract &&
      pmeToMint.length < pmeMaxMint
    ) {
      pmeToMint.includes(id)
        ? setPmeToMint((prev) => prev.filter((x) => x !== id))
        : setPmeToMint((prev) => [...prev, id])
    }
  }

  const newFilter = (e) => {
    setFilters((prev) =>
      prev.filter((x) => !x.includes(e.target.value.split(':')[0]))
    )

    if (e.target.checked) {
      setFilters((prev) => [...prev, e.target.value])
    } else {
      setFilters((prev) => prev.filter((x) => x !== e.target.value))
    }
  }

  const loadMap = () => {
    loadModules([
      'esri/widgets/Search',
      'esri/WebScene',
      'esri/views/SceneView',
    ]).then(([Search, WebScene, SceneView]) => {
      const webscene = new WebScene({
        portalItem: {
          id: '1e974a25fb674e51b88d0fdf27545e4e',
        },
      })

      const view = new SceneView({
        container: 'sceneWrapper',
        map: webscene,
      })

      const searchWidget = new Search({
        view: view,
      })

      view.ui.add(searchWidget, {
        position: 'top-right',
      })
    })
  }

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target

    const isBottom =
      scrollHeight - scrollTop === clientHeight ||
      scrollHeight - scrollTop === clientHeight + 1

    if (isBottom) {
      setMapsToShow((prev) => (prev += 20))
    }
  }

  useEffect(() => {
    if (filters.length < 1) {
      setMapsForDisplay(pmeTokens)
      return
    }

    let maps = pmeTokens

    filters.forEach((filter) => {
      const parts = filter.split(':')
      const index = parts[0]
      const value = parts[1]

      const _maps = maps.filter(
        (pmeToken) => pmeToken.attributes[index].value === value
      )

      maps = [..._maps]
    })

    setMapsForDisplay(maps)
  }, [filters])

  useEffect(() => {
    checkConnection()
    getPmeMintedTokens()
    loadMap()
  }, [])

  useEffect(() => {
    const url = window.location.href

    if (!url.includes('#')) return

    const id = url.split('#')[1].trim()

    if (id.length === 6) {
      setFilters([])
      setFilterSearch(id)

      if (
        galleryRef.current &&
        isLoaded &&
        !url.includes('pme') &&
        !url.includes('cm')
      ) {
        galleryRef.current.scrollIntoView()
      }
    }
  }, [location, isLoaded])

  useEffect(() => {
    if (isLoaded) {
      const url = window.location.href

      if (url.includes('pme')) {
        pmeRef.current.scrollIntoView()
      } else if (url.includes('cm')) {
        cmRef.current.scrollIntoView()
      }
    }
  }, [isLoaded])

  return (
    <Wrapper>
      {!isLoaded && <Loader />}

      <Notification
        displayNotification={displayNotification}
        message={notificationMessage}
        isSuccessful={notificationSuccess}
      />

      <Navbar
        contract={cityMapsContract}
        initConnection={initConnection}
        userAddress={userAddress}
      />

      <Section>
        <ImageTitle ref={pmeRef} src={ProjectMetaExpeditionTitle} />

        <Title green>Map Parcel Token Exchange</Title>
        {!pmeContract && (
          <SmallText>
            Please connect wallet to exchange presale Map Parcels
          </SmallText>
        )}

        <Button lessMargin onClick={pmeContract ? pmeExchange : initConnection}>
          {pmeContract ? 'Exchange' : 'Connect Wallet'}
        </Button>

        <SmallText moreMargin>
          *** Please note exchanging presale tokens requires confirming 2 wallet
          transactions
        </SmallText>

        <Title green>Map Parcel Token Mint</Title>
        {pmeContract ? (
          <>
            <Title moreMargin>{`${pmeMinted}/${pmeMaxSupply}`}</Title>
            <Text>Selected Maps to Mint</Text>

            {pmeToMint.length < 1 ? (
              <SmallText>Please select a map</SmallText>
            ) : (
              pmeToMint.map((token) => (
                <SelectedToken key={token}>
                  <SelectedTokenText>
                    <PurpleText>ID</PurpleText> #{token}
                  </SelectedTokenText>
                  <Discard
                    onClick={() =>
                      setPmeToMint((prev) => prev.filter((x) => x !== token))
                    }
                    src={DiscardGfx}
                  />
                </SelectedToken>
              ))
            )}

            <Text moreMarginTop>
              1 Map Parcel = {pmeCost / 1000000000000000000} MATIC
            </Text>

            <SmallText>
              (Max mint amount per transaction is {pmeMaxMint})
            </SmallText>
          </>
        ) : (
          <SmallText>
            Please connect wallet and then select Map Parcels to mint
          </SmallText>
        )}

        <Button moreMargin onClick={pmeContract ? pmeMint : initConnection}>
          {pmeContract ? 'Mint' : 'Connect Wallet'}
        </Button>
        {pmeContract && (
          <Button moreMarginLeft onClick={pmeWlMint}>
            Whitelist Mint
          </Button>
        )}

        <Tokens>
          <Filters>
            <FilterTitle green moreMargin>
              {
                mapsForDisplay.filter((pmeToken) =>
                  pmeToken.name.split('-')[1].startsWith(filterSearch)
                ).length
              }{' '}
              / {pmeMaxSupply || '9336'}
            </FilterTitle>

            <FilterTitle>Search for Map Parcel</FilterTitle>
            <FilterSearchWrapper>
              <FilterSearch
                value={filterSearch}
                placeholder='Enter Map Parcel ID'
                onChange={(e) => setFilterSearch(e.target.value.trim())}
              />

              <FilterSearchDiscard
                visible={filterSearch.length > 0}
                onClick={() => setFilterSearch('')}
                src={DiscardGfx}
              />
            </FilterSearchWrapper>

            <FilterTitle>Properties:</FilterTitle>

            <PropertyTitle
              onClick={() => setContinentOpened(!continentOpened)}
              isOpened={continentOpened}
            >
              <Arrow isOpened={continentOpened} src={ArrowGfx} /> Continent
            </PropertyTitle>
            <Collapse isOpened={continentOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='0:Oceania'
                  value='0:Oceania'
                  checked={filters.includes('0:Oceania')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='0:Oceania'>Oceania</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='0:North America'
                  value='0:North America'
                  checked={filters.includes('0:North America')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='0:North America'>
                  North America
                </CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() => setCountryOpened(!countryOpened)}
              isOpened={countryOpened}
            >
              <Arrow isOpened={countryOpened} src={ArrowGfx} /> Country
            </PropertyTitle>
            <Collapse isOpened={countryOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='1:United States'
                  value='1:United States'
                  checked={filters.includes('1:United States')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='1:United States'>
                  United States
                </CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='1:United States Minor Outlying Islands'
                  value='1:United States Minor Outlying Islands'
                  checked={filters.includes(
                    '1:United States Minor Outlying Islands'
                  )}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='1:United States Minor Outlying Islands'>
                  United States Minor Outlying Islands
                </CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() => setStateOpened(!stateOpened)}
              isOpened={stateOpened}
            >
              <Arrow isOpened={stateOpened} src={ArrowGfx} /> State
            </PropertyTitle>
            <Collapse isOpened={stateOpened}>
              {allStates.map((state) => (
                <CheckboxWrapper key={state}>
                  <Checkbox
                    type='checkbox'
                    id={`2:${state}`}
                    value={`2:${state}`}
                    checked={filters.includes(`2:${state}`)}
                    onChange={(e) => newFilter(e)}
                  />
                  <CheckboxLabel htmlFor={`2:${state}`}>{state}</CheckboxLabel>
                </CheckboxWrapper>
              ))}
            </Collapse>

            <PropertyTitle
              onClick={() => setCoastalClubOpened(!coastalClubOpened)}
              isOpened={coastalClubOpened}
            >
              <Arrow isOpened={coastalClubOpened} src={ArrowGfx} /> Coastal Club
            </PropertyTitle>
            <Collapse isOpened={coastalClubOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='3:Yes'
                  value='3:Yes'
                  checked={filters.includes('3:Yes')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='3:Yes'>Yes</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='3:No'
                  value='3:No'
                  checked={filters.includes('3:No')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='3:No'>No</CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() => setMountainHighClubOpened(!mountainHighClubOpened)}
              isOpened={mountainHighClubOpened}
            >
              <Arrow isOpened={mountainHighClubOpened} src={ArrowGfx} />{' '}
              Mountain High Club
            </PropertyTitle>
            <Collapse isOpened={mountainHighClubOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='4:Yes'
                  value='4:Yes'
                  checked={filters.includes('4:Yes')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='4:Yes'>Yes</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='4:No'
                  value='4:No'
                  checked={filters.includes('4:No')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='4:No'>No</CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() => setFishingClubOpened(!fishingClubOpened)}
              isOpened={fishingClubOpened}
            >
              <Arrow isOpened={fishingClubOpened} src={ArrowGfx} /> Fishing Club
            </PropertyTitle>
            <Collapse isOpened={fishingClubOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='5:Yes'
                  value='5:Yes'
                  checked={filters.includes('5:Yes')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='5:Yes'>Yes</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='5:No'
                  value='5:No'
                  checked={filters.includes('5:No')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='5:No'>No</CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() =>
                setSportsCommissionerClubOpened(!sportsCommissionerClubOpened)
              }
              isOpened={sportsCommissionerClubOpened}
            >
              <Arrow isOpened={sportsCommissionerClubOpened} src={ArrowGfx} />{' '}
              Sports Commissioner Club
            </PropertyTitle>
            <Collapse isOpened={sportsCommissionerClubOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='6:Yes'
                  value='6:Yes'
                  checked={filters.includes('6:Yes')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='6:Yes'>Yes</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='6:No'
                  value='6:No'
                  checked={filters.includes('6:No')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='6:No'>No</CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() =>
                setAdventureSeekerClubOpened(!adventureSeekerClubOpened)
              }
              isOpened={adventureSeekerClubOpened}
            >
              <Arrow isOpened={adventureSeekerClubOpened} src={ArrowGfx} />{' '}
              Adventure Seeker Club
            </PropertyTitle>
            <Collapse isOpened={adventureSeekerClubOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='7:Yes'
                  value='7:Yes'
                  checked={filters.includes('7:Yes')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='7:Yes'>Yes</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='7:No'
                  value='7:No'
                  checked={filters.includes('7:No')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='7:No'>No</CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>

            <PropertyTitle
              onClick={() => setDesertRatClubOpened(!desertRatClubOpened)}
              isOpened={desertRatClubOpened}
            >
              <Arrow isOpened={desertRatClubOpened} src={ArrowGfx} /> Desert Rat
              Club
            </PropertyTitle>
            <Collapse isOpened={desertRatClubOpened}>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='8:Yes'
                  value='8:Yes'
                  checked={filters.includes('8:Yes')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='8:Yes'>Yes</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  type='checkbox'
                  id='8:No'
                  value='8:No'
                  checked={filters.includes('8:No')}
                  onChange={(e) => newFilter(e)}
                />
                <CheckboxLabel htmlFor='8:No'>No</CheckboxLabel>
              </CheckboxWrapper>
            </Collapse>
            <FilterText
              visible={filters.length > 0}
              onClick={() => setFilters([])}
            >
              Reset All
            </FilterText>
          </Filters>

          {mapsForDisplay.filter((pmeToken) =>
            pmeToken.name.split('-')[1].startsWith(filterSearch)
          ).length > 0 ? (
            <GalleryView onScroll={handleScroll} ref={galleryRef}>
              {filterSearch !== ''
                ? mapsForDisplay
                    .filter((pmeToken) =>
                      pmeToken.name.split('-')[1].startsWith(filterSearch)
                    )
                    .slice(0, pmeMaxSupply)
                    .map((pmeToken) => (
                      <ReactCardFlip
                        key={pmeToken.name}
                        isFlipped={flipped === pmeToken.name}
                      >
                        <TokenWrapper
                          onMouseEnter={() => setFlipped(pmeToken.name)}
                          onClick={() => handleTokenSelect(pmeToken.name)}
                          selected={pmeToMint.includes(
                            pmeToken.name.split('-')[1]
                          )}
                          minted={pmeMintedTokens.includes(
                            pmeToken.name.split('-')[1]
                          )}
                        >
                          <Token
                            src={pmeToken.image}
                            loading='lazy'
                            selected={pmeToMint.includes(
                              pmeToken.name.split('-')[1]
                            )}
                            minted={pmeMintedTokens.includes(
                              pmeToken.name.split('-')[1]
                            )}
                          ></Token>

                          <Checkmark
                            selected={pmeToMint.includes(
                              pmeToken.name.split('-')[1]
                            )}
                            src={CheckmarkGfx}
                          />
                          {pmeMintedTokens.includes(
                            pmeToken.name.split('-')[1]
                          ) && (
                            <Minted>
                              <MintedMark src={MintedGfx} />
                              <MintedText>Minted</MintedText>
                            </Minted>
                          )}
                        </TokenWrapper>
                        <TokenWrapper
                          onMouseLeave={() => setFlipped()}
                          onClick={() => handleTokenSelect(pmeToken.name)}
                          selected={pmeToMint.includes(
                            pmeToken.name.split('-')[1]
                          )}
                          minted={pmeMintedTokens.includes(
                            pmeToken.name.split('-')[1]
                          )}
                        >
                          <Token
                            src={pmeToken.image}
                            loading='lazy'
                            selected={pmeToMint.includes(
                              pmeToken.name.split('-')[1]
                            )}
                            minted={true}
                          ></Token>

                          <Backside>
                            <BacksideText
                              moreMargin={pmeMintedTokens.includes(
                                pmeToken.name.split('-')[1]
                              )}
                            >
                              Map Parcel ID{' '}
                              <BacksideWhiteText>
                                {pmeToken.name.split('-')[1]}
                              </BacksideWhiteText>
                            </BacksideText>

                            {pmeMintedTokens.includes(
                              pmeToken.name.split('-')[1]
                            ) && (
                              <LinkButton
                                href={`https://opensea.io/assets/matic/0x16c2cf181922f6ebc83e916a6dcbd58b2f992d88/${
                                  pmeToken.name.split('-')[1]
                                }`}
                                target='_blank'
                              >
                                View on OpenSea
                              </LinkButton>
                            )}
                          </Backside>
                        </TokenWrapper>
                      </ReactCardFlip>
                    ))
                : mapsForDisplay
                    .filter((pmeToken) =>
                      pmeToken.name.split('-')[1].startsWith(filterSearch)
                    )
                    .slice(0, mapsToShow)
                    .map((pmeToken) => (
                      <ReactCardFlip
                        key={pmeToken.name}
                        isFlipped={flipped === pmeToken.name}
                      >
                        <TokenWrapper
                          onMouseEnter={() => setFlipped(pmeToken.name)}
                          onClick={() => handleTokenSelect(pmeToken.name)}
                          selected={pmeToMint.includes(
                            pmeToken.name.split('-')[1]
                          )}
                          minted={pmeMintedTokens.includes(
                            pmeToken.name.split('-')[1]
                          )}
                        >
                          <Token
                            src={pmeToken.image}
                            loading='lazy'
                            selected={pmeToMint.includes(
                              pmeToken.name.split('-')[1]
                            )}
                            minted={pmeMintedTokens.includes(
                              pmeToken.name.split('-')[1]
                            )}
                          ></Token>

                          <Checkmark
                            selected={pmeToMint.includes(
                              pmeToken.name.split('-')[1]
                            )}
                            src={CheckmarkGfx}
                          />
                          {pmeMintedTokens.includes(
                            pmeToken.name.split('-')[1]
                          ) && (
                            <Minted>
                              <MintedMark src={MintedGfx} />
                              <MintedText>Minted</MintedText>
                            </Minted>
                          )}
                        </TokenWrapper>
                        <TokenWrapper
                          onMouseLeave={() => setFlipped()}
                          onClick={() => handleTokenSelect(pmeToken.name)}
                          selected={pmeToMint.includes(
                            pmeToken.name.split('-')[1]
                          )}
                          minted={pmeMintedTokens.includes(
                            pmeToken.name.split('-')[1]
                          )}
                        >
                          <Token
                            src={pmeToken.image}
                            loading='lazy'
                            selected={pmeToMint.includes(
                              pmeToken.name.split('-')[1]
                            )}
                            minted={true}
                          ></Token>

                          <Backside>
                            <BacksideText
                              moreMargin={pmeMintedTokens.includes(
                                pmeToken.name.split('-')[1]
                              )}
                            >
                              Map Parcel ID{' '}
                              <BacksideWhiteText>
                                {pmeToken.name.split('-')[1]}
                              </BacksideWhiteText>
                            </BacksideText>

                            {pmeMintedTokens.includes(
                              pmeToken.name.split('-')[1]
                            ) && (
                              <LinkButton
                                href={`https://opensea.io/assets/matic/0x16c2cf181922f6ebc83e916a6dcbd58b2f992d88/${
                                  pmeToken.name.split('-')[1]
                                }`}
                                target='_blank'
                              >
                                View on OpenSea
                              </LinkButton>
                            )}
                          </Backside>
                        </TokenWrapper>
                      </ReactCardFlip>
                    ))}
            </GalleryView>
          ) : (
            <GalleryViewEmpty>No maps matching criteria...</GalleryViewEmpty>
          )}
        </Tokens>
        <div
          id='sceneWrapper'
          style={{
            maxWidth: '1280px',
            height: '550px',
            margin: '0 auto',
            marginTop: '30px',
          }}
        ></div>
      </Section>

      <Section>
        <ImageTitle ref={cmRef} src={CityMapsTitle} />
        <Title green>City Maps Token Mint</Title>
        {cityMapsContract && (
          <>
            <Title>{`${cityMapsMinted}/${cityMapsMaxSupply}`}</Title>
            <AmountPicker>
              <SignWrapper
                onClick={() =>
                  setCityMapsToMint((prev) => {
                    if (prev === cityMapsMaxMint) return prev
                    return prev + 1
                  })
                }
              >
                <Plus src={PlusGfx} />
              </SignWrapper>
              <Counter>{cityMapsToMint}</Counter>
              <SignWrapper
                onClick={() =>
                  setCityMapsToMint((prev) => {
                    if (prev === 1) return prev
                    return prev - 1
                  })
                }
              >
                <Minus src={MinusGfx} />
              </SignWrapper>
            </AmountPicker>
            <Text moreMargin>
              1 City Map = {cityMapsCost / 1000000000000000000} MATIC
            </Text>
          </>
        )}
        <Button onClick={cityMapsContract ? cityMapsMint : initConnection}>
          {cityMapsContract ? 'Mint' : 'Connect Wallet'}
        </Button>
        <CityMaps src={CityMapsIllustration} />
      </Section>

      <Footer />
    </Wrapper>
  )
}

export default App
