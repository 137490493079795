import styled from 'styled-components'

import LogoGfx from '../assets/logo.png'
import NavBackground from '../assets/nav-background.png'

const white = '#ffffff'
const pink = '#FF00AA'
const black = '#000000'

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: url(${NavBackground});
  padding: 0 5em;

  @media screen and (max-width: 992px) {
    padding: 0 1em;
  }
`

const Logo = styled.img`
  height: 50px;
  position: relative;
  top: 3px;

  @media screen and (max-width: 992px) {
    height: 40px;
  }
`

const Button = styled.button`
  color: ${pink};
  background: ${black};
  border: 5px solid ${pink};
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  min-height: 50px;
  padding: 0.3em 1em;
  cursor: pointer;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background: ${pink};
    color: ${white};
  }

  @media screen and (max-width: 992px) {
    min-height: 40px;
    font-size: 16px;
    border: 3px solid ${pink};
  }
`

const Navbar = ({ contract, initConnection, userAddress }) => {
  let start
  let end

  if (contract) {
    start = userAddress.substring(0, 6)
    end = userAddress.substring(userAddress.length - 4, userAddress.length)
  }

  return (
    <Nav>
      <a href='https://www.metaworldmaps.xyz'>
        <Logo src={LogoGfx} />
      </a>
      <Button onClick={contract ? () => {} : initConnection}>
        {contract ? `Connected: ${start}...${end}` : 'Connect Wallet'}
      </Button>
    </Nav>
  )
}

export default Navbar
