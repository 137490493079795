import styled from 'styled-components'

import Icon from '../assets/icon.png'

const black = '#000000'

const Wrapper = styled.div`
  background: ${black};
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
`

const Spinner = styled.img`
  height: 100px;
  animation: rotation 2s infinite linear;
`

const Loader = () => {
  return (
    <Wrapper>
      <Spinner src={Icon} />
    </Wrapper>
  )
}

export default Loader
