import styled from 'styled-components'

const grey = '#adadad'

const FooterWrapper = styled.div`
  text-align: center;
  padding: 0 5em;
  padding-bottom: 5em;

  @media screen and (max-width: 992px) {
    padding: 0 1em;
    padding-bottom: 5em;
  }
`

const Line = styled.div`
  border-top: 1px solid ${grey};
  margin-bottom: 5em;
`

const FooterText = styled.p`
  font-size: 20px;
  font-weight: ${(props) => (props.regular ? '400' : 'bold')};
  margin: 0;
  margin-bottom: ${(props) => (props.moreMargin ? '1.5em' : '0.5em')};

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const SmallFooterText = styled.span`
  display: block;
  font-size: 20px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <Line></Line>
      <FooterText>
        In order to mint Metaworld Maps NFT please connect to the Polygon
        (MATIC) Network.
      </FooterText>
      <FooterText moreMargin>
        Please Note: Once you make a purchase, all sales are final.
      </FooterText>
      <FooterText moreMargin regular>
        www.metaworldmaps.xyz
      </FooterText>
      <SmallFooterText>
        ©{new Date().getFullYear()} Metaworld Maps LLC. All rights reserved
      </SmallFooterText>
    </FooterWrapper>
  )
}

export default Footer
