import React from 'react'
import ReactDOM from 'react-dom/client'
import { loadCss } from 'esri-loader'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'

loadCss('https://js.arcgis.com/4.23/esri/themes/dark/main.css')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
